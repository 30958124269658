import React, { useLayoutEffect, useRef, useState } from "react"

const RainCanvas = ({ buttonSize, location, darkMode }) => {
  const rainCanvasRef = useRef(null)
  const [canvasRain, setCanvasRain] = useState(null)
  const [ctx, setCtx] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [particleArray, setParticleArray] = useState([])
  const [rainParticleNum, setRainParticleNum] = useState(0)
  const [rainIncreaseInterval, setRainIncreaseInterval] = useState(null)
  const [rainDecreaseInterval, setRainDecreaseInterval] = useState(null)
  const [animationFrame, setAnimationFrame] = useState(null)
  let rainParticleArray = []
  let animation
  let buttonState = undefined
  const isBrowser = typeof window !== "undefined"

  class RainParticle {
    constructor(x, y, buttonState, buttonSize) {
      this.x = x
      this.y = y
      this.size = Math.random() * 2 + 3
      this.weight = Math.random() * 4 + 9
      this.directionX = 0.1
      this.onceThrough = false
      this.touched = false
      this.buttonState = buttonState
      if (buttonSize) {
        this.buttonX = buttonSize.x
        this.buttonY = buttonSize.y
        this.buttonWidth = buttonSize.width
      }
    }
    rebound() {
      if (this.y >= canvasRain.height - this.size) {
        if (!this.onceThrough) this.onceThrough = true
        this.weight = 0 + Math.random() * -1 - 1
        if (!this.touched) {
          let rebound = Math.random()
          // this.size -= 1;

          rebound <= 0.5
            ? (this.directionX = 0 + Math.random() * -0.5 - 0.6)
            : (this.directionX = 0 + Math.random() * 0.5 + 0.6)
          this.touched = true
        }
      }
    }
    hasRebounded() {
      if (this.onceThrough) {
        this.size -= 0.3
      }
    }
    respawn() {
      if (this.size <= 0) {
        if (this.onceThrough) this.onceThrough = false
        this.size = Math.random() * 2 + 3
        this.y = 0 + this.size
        this.x = Math.random() * canvasRain.width - 20
        this.weight = Math.random() * 4 + 9
        this.directionX = 0.1
        this.touched = false
        // this.buttonState = buttonState;
      }
    }
    buttonCollide() {
      if (
        this.buttonState &&
        this.y >= this.buttonY - this.size * 2 &&
        this.y <= this.buttonY + 50 &&
        this.x >= this.buttonX &&
        this.x <= this.buttonX + this.buttonWidth
      ) {
        if (!this.onceThrough) this.onceThrough = true
        this.weight = 0 + Math.random() * -1 - 1
        if (!this.touched) {
          let rebound = Math.random()
          // this.size -= 1;
          rebound <= 0.5
            ? (this.directionX = 0 + Math.random() * -0.5 - 0.6)
            : (this.directionX = 0 + Math.random() * 0.5 + 0.6)
          this.touched = true
          this.hasRebounded()
          this.respawn()
        }
      }
    }
    update() {
      this.weight += 0.01
      this.y += this.weight
      this.x += this.directionX

      this.rebound()
      this.hasRebounded()
      this.buttonCollide()
      this.respawn()
    }

    draw() {
      ctx.fillStyle = "#007d9d"
      ctx.beginPath()

      ctx.fillRect(this.x, this.y, this.size, this.size + 1)
    }
  }

  const initParticles = () => {
    for (let i = 0; i < rainParticleNum; i++) {
      rainParticleArray.push(
        new RainParticle(
          Math.random() * canvasRain.width,
          Math.random() * -canvasRain.height,
          buttonState,
          buttonSize
        )
      )
    }
  }

  const increaseRain = () => {
    let particleNum = rainParticleNum
    let tempRainArray = particleArray
    let particleLimit
    window.innerWidth < 800 ? (particleLimit = 40) : (particleLimit = 130)
    clearInterval(rainDecreaseInterval)
    const increaseInterval = setInterval(() => {
      // console.log(particleLimit)
      if (particleNum >= particleLimit) {
        clearInterval(increaseInterval)
      }
      // setRainParticleNum(particleNum + 1)
      // console.log(buttonSizeState)
      // console.log(canvasRain.height, pageRef.current.clientHeight)
      setRainParticleNum(particleNum++)
      tempRainArray.push(
        new RainParticle(
          Math.random() * canvasRain.width,
          Math.random() * -canvasRain.height,
          buttonState,
          buttonSize
        )
      )
      // console.log(rainParticleArray)
      setParticleArray([...tempRainArray])
      // reinitParticles()
      // console.log(rainParticleArray.at(-1))
    }, 300)
    setRainIncreaseInterval(increaseInterval)
  }

  const decreaseRain = () => {
    let particleNum = rainParticleNum
    let tempRainArray = particleArray
    clearInterval(rainIncreaseInterval)
    const decreaseInterval = setInterval(() => {
      if (particleNum <= 0) {
        clearInterval(decreaseInterval)
        cancelAnimationFrame(animation)
      }
      // setRainParticleNum((particleNum -= 10))
      // console.log(buttonSizeState)
      setRainParticleNum(particleNum--)
      // console.log("decreaseRain: ", tempRainArray.length)
      tempRainArray.pop()

      setParticleArray([...tempRainArray])
    }, 100)
    setRainDecreaseInterval(decreaseInterval)
  }

  const scrollAdjustButtonSize = () => {
    if (buttonSize) {
      // console.log(window.scrollY)
      buttonSize.y = buttonSize.y + window.scrollY
    }
  }

  const reinitParticles = () => {
    // console.log("reinit ", buttonSize, buttonState, particleArray.length)
    // console.log("reinit ", isHomepage())
    rainParticleArray = particleArray.map(item => {
      // rainParticleArray[index].buttonState = buttonState
      if (item) {
        item.buttonState = isHomepage()
        if (buttonSize) {
          item.buttonX = buttonSize.x
          item.buttonY = buttonSize.y
          item.buttonWidth = buttonSize.width
        }
      }

      return item
    })
  }

  const animate = () => {
    ctx.clearRect(0, 0, canvasRain.width, canvasRain.height)
    particleArray.forEach(item => {
      item.update()
      // item.update();
      item.draw()
    })
    animation = requestAnimationFrame(animate)
    setAnimationFrame(animation)
  }

  const isHomepage = () => {
    if (location) {
      return location.pathname === "/"
    }
  }

  useLayoutEffect(() => {
    if (ctx) {
      if (darkMode) {
        increaseRain()
      } else {
        decreaseRain()
      }
    }
  }, [ctx, darkMode])

  useLayoutEffect(() => {
    if (rainCanvasRef && !loaded) {
      const rainCanvas = rainCanvasRef.current
      setCanvasRain(rainCanvas)
      setCtx(rainCanvas.getContext("2d"))
    }
    if (ctx && !loaded) {
      setLoaded(true)
      initParticles()
      setParticleArray(rainParticleArray)
      animate()
    }
  }, [ctx, location, loaded])

  useLayoutEffect(() => {
    if (loaded) {
      cancelAnimationFrame(animationFrame)
      reinitParticles()
      animate()
    }
  }, [particleArray, canvasRain])

  useLayoutEffect(() => {
    if (loaded) {
      scrollAdjustButtonSize()
      reinitParticles()
    }
  }, [location, buttonSize])

  return (
    isBrowser && (
      <canvas
        ref={rainCanvasRef}
        style={{ position: "absolute", zIndex: "1" }}
        height={window.document.body.offsetHeight}
        width={window.innerWidth}
      ></canvas>
    )
  )
}

export default RainCanvas
