/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useState } from "react"
import "../styles/App.scss"
import Nav from "../components/Nav"
import Footer from "../components/Footer"
import RainCanvas from "../components/RainCanvas"
import { Helmet } from "react-helmet"
import favicon from "../assets/logo.png"

const Layout = ({ children, location }) => {
  const [hireButtonState, setHireButtonState] = useState(null)
  const [hireButtonSize, setHireButtonSize] = useState(null)
  const [darkMode, setDarkMode] = useState(true)
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      setButtonState: setHireButtonState,
      setHireButtonSize: setHireButtonSize,
    })
  )
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <div
        className={`${
          darkMode ? "dark-theme dark-theme-bg" : "light-theme light-theme-bg"
        } app`}
      >
        <Nav
          setDarkMode={setDarkMode}
          darkMode={darkMode}
          location={location}
        />
        <RainCanvas
          location={location}
          buttonState={hireButtonState}
          buttonSize={hireButtonSize}
          darkMode={darkMode}
        />
        <main>{childrenWithProps}</main>
        {location && location.pathname.replace(/\/+$/, "") !== "/about" ? (
          <Footer links={true} />
        ) : (
          <Footer links={false} />
        )}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
