import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Footer = ({ links }) => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      strapiFooter {
        links {
          title
          url
        }
      }
    }
  `)
  return (
    links && (
      <footer>
        <ul>
          {data &&
            data.strapiFooter.links.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.url}>{item.title}</a>
                </li>
              )
            })}
        </ul>
        <p>Copyright 2022 Dareos Meyer</p>
      </footer>
    )
  )
}

export default Footer
