import React, { useState, useLayoutEffect } from "react"
import { Link } from "gatsby"
import cloudsBg from "../assets/cloud-bg.png"
import { Icon } from "@iconify/react"

const Nav = ({ setDarkMode, darkMode, location }) => {
  const [menuClosed, setMenuClosed] = useState(true)

  useLayoutEffect(() => {
    if (!menuClosed) {
      setMenuClosed(true)
    }
  }, [location])

  return (
    <nav className={`${menuClosed ? "" : "openMenu"}`}>
      <div
        className={`${darkMode ? "dark-theme-nav" : "light-theme-nav"} nav-bg `}
        style={{ backgroundColor: `${darkMode ? "#808080" : "#F3F3F3"}` }}
      >
        <div className="link-container">
          <ul className="links">
            <li>
              <Link to="/">
                <button>Home</button>
              </Link>
            </li>

            <li>
              <Link to="/projects">
                <button>Projects</button>
              </Link>
            </li>
            <li>
              <Link to="/experiments">
                <button>Experiments</button>
              </Link>
            </li>
            <li>
              <Link to="/about">
                <button>About me</button>
              </Link>
            </li>
          </ul>
          <button
            className="toggle-theme-button"
            onClick={() => {
              darkMode ? setDarkMode(false) : setDarkMode(true)
              setMenuClosed(true)
            }}
            aria-label="Visual theme toggle"
          >
            <Icon icon={darkMode ? "bi:sun" : "bi:cloud-rain"} width="35" />
          </button>
        </div>
        <button
          className={`menu-toggle ${menuClosed ? "" : "open"}`}
          onClick={() => {
            menuClosed ? setMenuClosed(false) : setMenuClosed(true)
          }}
          aria-label="Navigation menu toggle"
        >
          <div className={`btn ${menuClosed ? "not-active" : "active"}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      <div className="clouds-container">
        <div
          className="clouds-bg"
          style={{
            backgroundImage: `url(${cloudsBg})`,
            // filter: `${darkMode ? "none" : "invert(0.7)"}`,
            filter: `${darkMode ? "none" : "contrast(50%) brightness(190%)"}`,
          }}
        ></div>
      </div>
    </nav>
  )
}

export default Nav
